import React from "react";
import { Link } from "gatsby";
import Head from "../components/head.js";

import Layout from "../components/layout";

import "./about.css";

const About = () => {
  return (
    <Layout>
      <Head title="About" />
      {/* <p className="aboutP">
        <strong>artnow.world</strong>'s purpose is to connect artists all around
        the world.
      </p>

      <p className="aboutP">
        To make it easier for us to connect, communicate, and collaborate.
      </p> */}
      {/* <p className="aboutP">
        <strong>artnow.world</strong>'s purpose is to be a directory for artists
        of all kinds from all over the world to connect.
      </p>
      <p className="aboutP">
        The concept of a directory might feel very web 1.0. Yes, this site is
        hearkening back to those times.
      </p>
      <p className="aboutP">
        Think of it as somewhere between a social media profile, a portfolio, a
        business card with contact information / links, and a phone book. And if
        you want to publish writing on here, feel free to send it in.
      </p> 
      
              I will say
        that making it has felt like an index for sure. kind of like, compiling
        a pokedex of people / artists / creators / etc etc etc/, like poets and
        musicians and videographers and writers and programmers and illustrators
        and animators and writers again (for all the kinds of writing they do)
        
        */}

      {/* <p className="aboutP">
        Here is my latest writing trying to write an about page for this website
        / idea:
      </p>

      <p className="aboutP">
        <b>artnow.world</b>'s goal is to help people find out and discover /
        discover and find out / connect people around the concept of art,
        whatever form or media it happens to be. / help people / connect people
        around the topic of art / expression. organized around the person /
        group who did it, at least for now / in its current conception /
        iteration. and also be like a "non social social media website". There's
        no comments, there's no posting, there's no interaction per se. just
        your personal / visitor experience and links off it to the artist's
        social media if they have it, or website, etc. and you can follow that
        off of here if you want too.
      </p> */}

      <p className="aboutP">
        <b>artnow.world</b>'s purpose is to help you discover artists you might
        not know about from around the world and to keep up on their latest news
        / developments if they so choose to use the website that way!
      </p>
      <h3>
        <Link
          to="/artists"
          style={{
            borderBottom: "1px solid #fff",
            paddingBottom: "2px",
            lineHeight: "1.3",
          }}
        >
          Check it out
        </Link>
      </h3>
      {/* <p>-----</p>
      <p>Connect with artnow.world on Social Media as well!</p>
      <h3>
        <a href="https://facebook.com/artnow.world">Facebook</a>
      </h3>
      <h3>
        <a href="https://instagram.com/artnow.world">Instagram</a>
      </h3> */}
      {/* <p className="aboutP">
        This website / concept is very new and I'm also open to hearing feedback
        / ideas, and also what you think this website could be.
      </p> */}
      {/* <p>
        <em>Summer 2022</em>
      </p> */}
    </Layout>
  );
};

export default About;
